<template>
  <div class="mo-reserve-wrap">
    <div class="mo-reserve-wrap--title">참여완료</div>
    <div class="mo-reserve-wrap--survey-form">
      <!-- 질문1: 객관식 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title" :class="{'required': SURVEY_Q01.required == true }">{{ SURVEY_Q01.text }}</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <v-radio-group v-model="radioGroupSelected">
            <v-radio
              v-for="(rad01,index) in SURVEY_A01" :key="SURVEY_A01[index].title" :label="rad01.title"
              :on-icon="'svg-rad-on'"
              :off-icon="'svg-rad-off'"
              :value="rad01.title">
            </v-radio>
          </v-radio-group>
        </div>
      </div>
      <!-- 질문2: 객관식 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title" :class="{'required': SURVEY_Q02.required == true }">{{ SURVEY_Q02.text }}</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <v-radio-group v-model="radioGroupSelected2">
            <v-radio
              v-for="(rad01,index) in SURVEY_A02" :key="SURVEY_A01[index].title" :label="rad01.title"
              :on-icon="'svg-rad-on'"
              :off-icon="'svg-rad-off'"
              :value="rad01.title">
            </v-radio>
          </v-radio-group>
        </div>
      </div>
      <!-- 질문3: 주관식 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title"
          :class="{'required': SURVEY_Q03.required == true }">
          {{ SURVEY_Q03.text }}
        </div>
        <div class="mo-reserve-wrap--survey-form-subtext">
          {{ SURVEY_Q03.subtext }}
        </div>
        <div class="mo-reserve-wrap--survey-form-a">
          <v-textarea
            counter="200"
            placeholder="내용을 입력해주세요.(200자 까지 가능)"
            :rules="textareaRules"
            no-resize
            outlined
          >
        </v-textarea>
        </div>
      </div>
      <!-- 질문4: 파일 업로드 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title"
          :class="{'required': SURVEY_Q04.required == true }">
          {{ SURVEY_Q04.text }}
        </div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="file-upload-wrap">
            <div class="file-upload-print">
              <img src="@/assets/file_upload_default.png" alt="">
            </div>
            <div class="file-upload-txt">
              이미지 파일을 업로드 해주세요.
              <button type="button" class="btn-upload">파일 업로드</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 질문5: 객관식 복수 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title"
          :class="{'required': SURVEY_Q05.required == true }">
          {{ SURVEY_Q05.text }}
        </div>
        <div class="mo-reserve-wrap--survey-form-a">
          <v-checkbox
            v-for="(chk01,index) in SURVEY_A05"
            :key="SURVEY_A05[index].text"
            :label="chk01.text"
            :on-icon="'svg-chk-on'"
            :off-icon="'svg-chk-off'"
            v-model="chk01.selected"
            ></v-checkbox>
        </div>
      </div>
      <!-- 질문6: 선택형 -->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title"
          :class="{'required': SURVEY_Q06.required == true }">
          {{ SURVEY_Q06.text }}
        </div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="form-inp pt-0">
            <v-select
              :items="SURVEY_A06"
              item-text="title"
              outlined
              placeholder="선택하세요"
            ></v-select>
          </div>
        </div>
      </div>

    </div>

    
    <div class="mo-reserve-wrap--title mt-15">예약하기</div>
    <div class="mo-reserve-wrap--survey-form type-reserve">
      <!-- 방문예약 : 선택 전?-->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title required">방문예약</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="mo-reserve-wrap--reserve">
            <div class="mo-reserve-wrap--reserve--time"></div>
            <button type="button" class="btn-round">선택</button>
          </div>
        </div>
      </div>
      <!-- 방문예약 : 달격-->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form--calendar">
          <div class="calendar-wrap">
            <div class="calendar-modal">
              <v-date-picker
                v-model="calendar"
                :events="arrayEventsCalendar"
                full-width
                no-title
                color="#F4B228"
                event-color="#F4B228"
                @change="calendarLog"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
          </div>
        </div>
        <!-- 시간선택 -->
        <div class="mo-reserve-wrap--survey-form--timepick-wrap">
          <div class="mo-reserve-wrap--survey-form--timepick"
            v-for="(item, index) in TIME_LIST" :key="index"
            @click="FnTimePick(item)"
          >
            <div class="mo-reserve-wrap--survey-form--timepick-inner"
               :class="{ 'selected': item.selected }">
              <strong>{{ item.time }}</strong>
              <span>잔여 {{ item.remaining }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 방문예약 : 선택 후?-->
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title required">방문예약</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="mo-reserve-wrap--reserve">
            <div class="mo-reserve-wrap--reserve--time"> {{ dayConvert(SELECT_DATE) }} ({{ WEEKDAY }}) {{ TIME_PICKED.ampm == 'am' ? '오전' : '오후'}} {{ TIME_PICKED.time }}</div>
            <button type="button" class="btn-round">선택</button>
          </div>
        </div>
      </div>
    </div>


    <div class="mo-reserve-wrap--btn type-free">
      <button type="button">제출하기</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CAP0600',
    components: {},
    props: {},
    data() {
      return {
        radioGroupSelected:'',
        radioGroupSelected2:'',
        textareaRules: [v => v?.length <= 200 || '200자 제한'],
        calendar: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        arrayEvents: [
          {
            "id": 0,
            "day": "2022-09-01",
            "titleLS": "기념일1",
            "desc": "기념일 세부내용",
            "type": "evt",
            "title": "기념일",
            "icon": "svg-dot-evt"
          },
          {
            "id": 1,
            "day": "2022-09-03",
            "titleLS": "기념일222",
            "desc": "기념일 세부내용222",
            "type": "evt",
            "title": "기념일",
            "icon": "svg-dot-evt"
          },
          {
            "id": 2,
            "day": "2022-09-04",
            "titleLS": "스케쥴임",
            "desc": "스케쥴 세뷰내용",
            "type": "schedule",
            "title": "스케쥴",
            "icon": "svg-dot-sch"
          }
        ],
        // 질문1
        SURVEY_Q01: {
          text: '귀하의 연령대를 선택하세요.',
          required: true
        },
        SURVEY_A01: [
          { title: '20대' },
          { title: '30대' },
          { title: '40대' },
          { title: '50대' },
          { title: '60대 이상' },
        ],
        SURVEY_Q02: {
          text: '현재 거주 지역을 선택하세요.',
          required: true
        },
        SURVEY_A02: [
          { title: '광양시' },
          { title: '순천시' },
          { title: '여수시' },
          { title: '전라남도' },
        ],
        SURVEY_Q03: {
          text: '더샵 카카오톡 채널 5주년을 축하해주세요.',
          subtext: 'ex) 5주년 축하하고 더 많은 정보를 주세요',
          required: true
        },
        SURVEY_Q04: {
          text: '더샵 카카오톡 채널 5주년을 축하해주세요.',
          required: false
        },
        SURVEY_Q05: {
          text: '선호하는 평형을 모두 선택해주세요.',
          required: false
        },
        SURVEY_Q06: {
          text: '현재 거주 지역을 선택 해주세요.',
          required: false
        },
        SURVEY_A05: [
          {
            text: '18평',
            selected: true
          },
          {
            text: '24평',
            selected: true
          },
          {
            text: '30평',
            selected: false,
          },
          {
            text: '35평',
            selected: false,
          },
          {
            text: '40평',
            selected: false,
          },
        ],
        SURVEY_A06: [
          { title: '경기도' },
        ],
        TIME_LIST: [
          {
            time: '10:00',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '10:30',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '11:00',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '11:30',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '12:00',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '12:30',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '13:00',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
          {
            time: '13:30',
            remaining: '100',
            ampm: 'pm',
            selected: false
          },
          {
            time: '14:00',
            remaining: '100',
            ampm: 'pm',
            selected: false
          },
          {
            time: '10:30',
            remaining: '100',
            ampm: 'am',
            selected: false
          },
        ],
        TIME_PICKED: '00:00',
        SELECT_DATE: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        WEEKDAY: null,
      }
    },
    mounted() {},
    computed: { },
    methods: {
      // 시간 선택
      FnTimePick(item) {
        for (let i = 0; i < this.TIME_LIST.length; i++) {
          this.TIME_LIST[i].selected = false;

        }
        this.TIME_LIST[this.TIME_LIST.indexOf(item)].selected = true;
        this.TIME_PICKED = {
          time: this.TIME_LIST[this.TIME_LIST.indexOf(item)].time,
          ampm: this.TIME_LIST[this.TIME_LIST.indexOf(item)].ampm
        }
      },
      // 날짜 변환
      dayConvert(e) {
        const day = e.split("-");
        return `${day[0]}.${day[1]}.${day[2]}`;
      },
      getDay(date){
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        let i = new Date(date).getDay(date);
        return this.WEEKDAY = daysOfWeek[i];
      },
      //캘린더에 포인트 찍기
      arrayEventsCalendar(date) {
        let colorArray = [];

        for (let i = 0; i < this.arrayEvents.length; i++) {
          if (this.arrayEvents[i].day === date) {
            if (this.arrayEvents[i].type === "schedule") {
              colorArray.push("#6DC4B1");
            } else if (this.arrayEvents[i].type === "evt") {
              colorArray.push("#F7B611");
            }
          }
        }
        return colorArray;
      },
      //캘린더 클릭 로그
      calendarLog(e) {
        console.log("date = " + e);
        this.SELECT_DATE = e;
        this.getDay(e);
        console.log("day = " + this.getDay(e));

        const index = this.arrayEvents.findIndex(function (item) {
          return item.day === e;
        });

        if (index < 0) return;

        console.log("index = " + index);
        this.arrayEventDetail = this.arrayEvents[index].desc ?? this.arrayEvents[index].desc;

      },




    }
  }
</script>

<style>

</style>